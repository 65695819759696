import { useQuery } from "@tanstack/react-query";
import { SdrBasedErrors } from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";
import { IDistributionSummary } from "../../interfaces/SdrApi/IDistributionSummary";
import bitrixApiService from "../../services/bitrixAdapterApi";

const useDistributionSummary = (query?: Object, definitions?: Object) =>
    useQuery<IDistributionSummary[], AxiosError<SdrBasedErrors>>({
        ...definitions,
        queryKey: ["distribution-summary", query],
        queryFn: async () => {
            const response = await bitrixApiService.getDistributionsSummary(query);
            return response.data;
        },
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
    });

export default useDistributionSummary;
