import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";

interface HunterEditInputCellProps extends GridRenderEditCellParams {
  hunters: IFrexcoSeller[];
}

export const HunterEditInputCell = (props: HunterEditInputCellProps) => {
  const { id, value, api, field, hunters } = props;

  const handleChange = (event: any, newValue: IFrexcoSeller | null) => {
    api.setEditCellValue({ id, field, value: newValue?.name || '' });
  };

  return (
    <Autocomplete
    options={hunters}
    getOptionLabel={(option) => option.name}
    value={hunters.find((hunter) => hunter.name === value) || null}
    onChange={handleChange}
    noOptionsText="Nenhuma opção"
    renderInput={(params) => (
      <TextField
        {...params}
        variant="standard"
        sx={{ padding: '2px 4px' }}
      />
    )}
    fullWidth
    sx={{ minWidth: '200px' }}
  />
  );
};