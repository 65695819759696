export const handleRecommendationError = (errorMessage: string) => {
    switch (true) {
        case errorMessage.includes("lead já existente"):
            return "O lead já está cadastrado no Bitrix.";

        case errorMessage.includes("endereço não encontrado"):
            return "O endereço fornecido não foi encontrado. Verifique e tente novamente.";

        case errorMessage.includes("fora da área de entrega"):
            return "O endereço está fora da área de entrega.";

        case errorMessage.includes("erro ao criar negociação") || errorMessage.includes("erro interno no servidor"):
            return "Houve um problema ao criar a negociação. Tente novamente.";

        default:
            return errorMessage;
    }
};