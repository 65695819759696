import { FieldValues } from "react-hook-form/dist/types";
  
  interface IMappedObject {
    id: null;
    catalog_id: null;
    automatic: boolean;
    seller_id: string;
    client: {
      id: null;
      cpf_cnpj: string;
      trade_name: string;
      phone_number_1: string;
      phone_number_2?: string;
      email: string;
      responsible_name: string;
    };
    billing_address: {
      street: string;
      complement?: string;
      city: string;
      zip: string;
      state: string;
      neighborhood: string;
      number: string;
    };
  }
  
  export const mapPayloadToClient = (payload: FieldValues): IMappedObject => {
    return {
      id: null,
      catalog_id: null,
      automatic: false,
      seller_id: payload.seller_id,
      client: {
        id: null,
        cpf_cnpj: payload.leadCnpj,
        trade_name: payload.leadName,
        phone_number_1: payload.formattedPhoneNumber1,
        phone_number_2: payload.formattedPhoneNumber2 || "",
        email: payload.leadMailAddress,
        responsible_name: "",
      },
      billing_address: {
        street: payload.street,
        complement: payload.number,
        city: payload.city,
        zip: payload.zipcode,
        state: payload.state,
        neighborhood: payload.neighborhood,
        number: payload.number,
      },
    };
  };