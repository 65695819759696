import { useState } from "react";
import { Typography } from "@mui/material";

import Box from "@mui/material/Box";

import Button from "../../../components/Button";
import ClientRecommendedForm from "./ClientRecommendedForm";
import OthersRecommendationsForm from "./OthersRecomentationsForm";

import { useStyles } from "./styles";

import { PermissionGate } from "../../../components/PermissionGate";

import { useAuth } from "../../../hooks/useAuth";
import ComponentHeader from "../../../components/ComponentHeader";

const Recommend = () => {
    const classes = useStyles();
    const { permissions } = useAuth();

    const [clientRecommended, setClientRecommended] = useState(true);
    const [othersRecommended, setOthersRecommended] = useState(false);

    const handleOnClickClientRecommended = () => {
        if (othersRecommended) setOthersRecommended(false);
        if (!clientRecommended) setClientRecommended(true);
    };

    const handleOnClickothersRecommended = () => {
        if (clientRecommended) setClientRecommended(false);
        if (!othersRecommended) setOthersRecommended(true);
    };

    return (
        <div>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Recomendação de Leads" />
            </ComponentHeader.Root>
            <Box className={classes.contentContainer}>
                <Typography variant="body1" textAlign={"justify"} marginTop={4}>
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["client recommended"]}
                    >
                        Para cadastrar leads no Bitrix através da Recomendação, você pode optar pela função "Selecionar Hunter". 
                        Isso permitirá que você escolha manualmente um hunter ativo da lista da Frexco. 
                        É importante fornecer um CNPJ de indicação. 
                        Caso não possua um CNPJ específico, você pode repetir o CNPJ do Lead que está sendo cadastrado.
                    </PermissionGate>
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["want to recommend"]}
                    >
                        {""} A opção "Enviar Automaticamente" permite que você cadastre leads no Bitrix através da Recomendação, 
                        enviando a indicação diretamente ao hunter com o maior número de vendas nos últimos 7 dias.
                    </PermissionGate>
                </Typography>
                <Box marginTop={4}>
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["client recommended"]}
                    >
                        <Button
                            sx={{
                                backgroundColor: clientRecommended
                                    ? "#689F38"
                                    : "",
                            }}
                            onClick={handleOnClickClientRecommended}
                        >
                            <Typography variant="h4">
                                Selecionar Hunter
                            </Typography>
                        </Button>
                    </PermissionGate>
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["want to recommend"]}
                    >
                        <Button
                            sx={{
                                backgroundColor: othersRecommended
                                    ? "#689F38"
                                    : "",
                            }}
                            onClick={handleOnClickothersRecommended}
                        >
                            <Typography variant="h4">
                                Enviar Automaticamente
                            </Typography>
                        </Button>
                    </PermissionGate>
                </Box>
                <Box>
                    {clientRecommended && <ClientRecommendedForm />}
                    {othersRecommended && <OthersRecommendationsForm />}
                </Box>
            </Box>
        </div>
    );
};

export default Recommend;
