import { Box, Toolbar } from "@mui/material";

interface IContent {
  children: React.ReactNode;
}

export const Content = ({ children }: IContent) => {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#FAFAFA",
        flexGrow: 1,
        paddingLeft: "0.99rem",
        paddingRight: "0.99rem",
        paddingTop: "2.2rem",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Box>{children}</Box>
    </Box>
  );
};
