import { useState, useEffect } from "react";
import { Autocomplete, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { usePopup } from "../../../hooks/usePopup";
import useCepInfos from "../../../hooks/BrasilApi/useCepInfos";
import useRdstationCities from "../../../hooks/SdrApi/useRdstationCities";
import useCepInfosV2 from "../../../hooks/BrasilApi/useCepInfosV2";

import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { AlertDialog } from "../../../components/AlertDialog";

import { useStyles } from "./styles";

import { ICepInfo } from "../../../interfaces/BrasilApi/ICepInfos";
import { validateCep } from "../../../utils/validateCep";
import { mapPayloadToClient } from "./utils/ClientMapper";
import bitrixApiService from "../../../services/bitrixAdapterApi";
import { handleRecommendationError } from "./utils/handleRecommendationError";

const formSchema = yup.object().shape({
    leadName: yup
        .string()
        .required("O nome da organização é obrigatório")
        .trim(),
    leadCnpj: yup
        .string()
        .required("O CNPJ do lead é obrigatório")
        .transform((value, originalValue) => {
            if (originalValue) {
                return originalValue.replace(/[./-]/g, "");
            }
            return value;
        })
        .matches(
            /^([0-9]{14}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
            "Formato de CNPJ inválido"
        )
        .trim()
        .length(14),
    formattedPhoneNumber1: yup
        .string()
        .required("O número principal do lead é obrigatório")
        .trim()
        .max(14)
        .min(10),
    formattedPhoneNumber2: yup.string().trim(),
    leadMailAddress: yup.string().email().trim(),
    street: yup
        .string()
        .required("A rua do endereço do lead é obrigatório")
        .trim(),
    number: yup
        .string()
        .required("O número do endereço do lead é obrigatório")
        .trim(),
    neighborhood: yup
        .string()
        .required("O bairro do endereço do lead é obrigatório")
        .trim(),
    zipcode: yup
        .string()
        .required("O CEP do endereço do lead é obrigatório")
        .transform((value) =>
            value.length === 9 ? value.replace("-", "") : value
        )
        .length(8)
        .trim(),
    city: yup
        .string()
        .required("A cidade do endereço do lead é obrigatório")
        .transform((value) =>
            value === "São Caetano do Sul"
                ? "São Caetano"
                : value === "São Bernardo do Campo"
                ? "São Bernardo"
                : value
        )
        .trim(),
    state: yup
        .string()
        .required("O estado do endereço do lead é obrigatório")
        .trim(),
    otherIndications: yup.boolean().default(true),
});

const OthersRecommendationsForm = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [sendingRecommendation, setSendingRecommendation] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [recommendationTitle, setRecommendationTitle] = useState("");
    const [recommendationResult, setRecommendationResult] = useState("");
    const [cep, setCep] = useState("");
    const [cepInfos, setCepInfos] = useState<ICepInfo>();

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue        
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { data: cepInfosFromApi, isError: getCepInfosFromApiIsError } =
        useCepInfos(cep, {
            enabled: !!cep,
        });

    const { data: cepInfosFromApiV2, isError: getCepInfosFromApiIsErrorV2 } =
        useCepInfosV2(cep, {
            enabled: getCepInfosFromApiIsError,
        });

    const {
        data: rdstationCitiesResponse,
        isFetching: gettingRdstationCities,
    } = useRdstationCities();


    const onSubmit = async (data: FieldValues) => {
        setSendingRecommendation(true);
        addPopup({
            title: "Recomendação enviada ao servidor.",
            description:
                "Por favor, espere até que uma nova mensagem seja exibida com o resultado da recomendação.",
            type: "info",
            duration: "permanent",
        });

        let mappedData = mapPayloadToClient(data);

        mappedData.automatic = true;
        
        try {
            const response = await bitrixApiService.postRecommendation(mappedData);            

            setRecommendationTitle("Recomendação enviada com sucesso!");
            setRecommendationResult(
                `A recomendação foi enviada com sucesso para o hunter ${response.data.hunterName}.`
            );
        } catch (error: any) {
            const serverMessage = error.response?.data?.error?.toLowerCase() || "";

            let errorMessage = "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.";

            if (serverMessage) {
                errorMessage = handleRecommendationError(serverMessage);
            }
    
            setRecommendationTitle("Erro ao enviar recomendação.");
            setRecommendationResult(errorMessage);
        } finally {
            setSendingRecommendation(false);
            setOpenAlertDialog(true);
        }
    };      

    const handleCepTextFildChange = (cep: string) => {
        if (validateCep(cep)) {
            if (cep.length === 9) {
                cep = cep.slice(0, 5) + cep.slice(6);
            }
            setCep(cep);
        }
    };

    useEffect(() => {
        if (cepInfosFromApi || cepInfosFromApiV2) {
            const cepInfos = cepInfosFromApi
                ? cepInfosFromApi
                : cepInfosFromApiV2;
            setCepInfos(cepInfos);
            setValue("state", cepInfos?.state);
            setValue("city", cepInfos?.city);
            setValue("neighborhood", cepInfos?.neighborhood);
            setValue("street", cepInfos?.street);
        }
    }, [cepInfosFromApi, cepInfosFromApiV2, setValue]);

    useEffect(() => {
        if (getCepInfosFromApiIsErrorV2) {
            addPopup({
                title: "Preencha o endereço manualmente.",
                description:
                    "Não foi possível buscar as informações de endereço, por favor preencha-as manualmente.",
                type: "info",
                duration: "permanent",
            });
        }
    }, [getCepInfosFromApiIsErrorV2]);

    return (
        <div>
            <Box>        
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography marginTop={4} marginBottom={2} variant="h6">Dados Gerais do Lead</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Nome da Organização *"
                                type="text"
                                fullWidth
                                {...register("leadName")}
                                error={!!errors?.leadName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="CNPJ do Lead *"
                                type="text"
                                fullWidth
                                {...register("leadCnpj")}
                                inputProps={{ maxLength: 14 }}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                                error={!!errors?.leadCnpj}
                                helperText={<>{errors?.leadCnpj?.message}</>}
                            />
                        </Grid>
                    </Grid>
        
                    <Typography variant="h6" marginTop={2} marginBottom={2}>
                        Dados de Contato do Lead
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Telefone 01 *"
                                type="text"
                                fullWidth
                                {...register("formattedPhoneNumber1")}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                                inputProps={{ maxLength: 11 }}
                                error={!!errors?.formattedPhoneNumber1}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Telefone 02"
                                type="text"
                                fullWidth
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                                {...register("formattedPhoneNumber2")}
                                inputProps={{ maxLength: 11 }}
                                error={!!errors?.formattedPhoneNumber2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Email"
                                type="text"
                                fullWidth
                                {...register("leadMailAddress")}
                                error={!!errors?.leadMailAddress}
                            />
                        </Grid>
                    </Grid>
        
                    <Typography variant="h6" marginTop={2} marginBottom={2}>
                        Endereço do Lead: insira o CEP para buscar o endereço completo
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="CEP *"
                                placeholder="00000-000"
                                type="text"
                                fullWidth
                                {...register("zipcode")}
                                error={!!errors?.zipcode}
                                inputProps={{ maxLength: 9 }}
                                onChange={(event) =>
                                    handleCepTextFildChange(event.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Logradouro *"
                                type="text"
                                fullWidth
                                {...register("street")}
                                error={!!errors?.street}
                                focused={!!cepInfos?.street}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Número *"
                                type="text"
                                fullWidth
                                {...register("number")}
                                error={!!errors?.number}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Bairro *"
                                type="text"
                                fullWidth
                                {...register("neighborhood")}
                                error={!!errors?.neighborhood}
                                focused={!!cepInfos?.neighborhood}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Estado *"
                                type="text"
                                fullWidth
                                {...register("state")}
                                error={!!errors?.state}
                                focused={!!cepInfos?.state}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {!getCepInfosFromApiIsErrorV2 ? (
                                <TextField
                                    {...register("city")}
                                    label="Cidade *"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors?.city}
                                    focused={!!cepInfos?.city}
                                    helperText={<>{errors?.city?.message}</>}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!getCepInfosFromApiIsErrorV2}
                                />
                            ) : (
                                <Autocomplete
                                    options={
                                        rdstationCitiesResponse
                                            ? rdstationCitiesResponse?.cities
                                            : []
                                    }
                                    loading={gettingRdstationCities}
                                    getOptionLabel={(option) => option}
                                    className={classes.inputTextField}
                                    renderInput={(params) => (
                                        <TextField
                                            {...register("city")}
                                            {...params}
                                            label="Cidade *"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors?.city}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </Grid>
        
                    <Box marginTop={2} marginBottom={8}>
                        <Button
                            disabled={sendingRecommendation}
                            className={classes.buttonBox}
                            type="submit"
                            fullWidth
                        >
                            <Typography variant="h4">Enviar</Typography>
                        </Button>
                    </Box>
                </form>
            </Box>
            {openAlertDialog && (
                <AlertDialog
                    setOpenDialog={setOpenAlertDialog}
                    openDialog={openAlertDialog}
                    title={recommendationTitle}
                    message={recommendationResult}
                />
            )}
        </div>
    );
};

export default OthersRecommendationsForm;
