import { useState, useEffect } from "react";
import { AxiosError, AxiosResponse } from "axios";

import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";

import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { AlertDialog } from "../../../components/AlertDialog";

import { useStyles } from "./styles";
import useCepInfos from "../../../hooks/BrasilApi/useCepInfos";
import { usePopup } from "../../../hooks/usePopup";
import useCepInfosV2 from "../../../hooks/BrasilApi/useCepInfosV2";

import { ICepInfo } from "../../../interfaces/BrasilApi/ICepInfos";
import { validateCep } from "../../../utils/validateCep";
import { mapPayloadToClient } from "./utils/ClientMapper";
import bitrixApiService from "../../../services/bitrixAdapterApi";
import { handleRecommendationError } from "./utils/handleRecommendationError";

const formSchema = yup.object().shape({
    whoReferredCnpj: yup
        .string()
        .required("O CNPJ de quem indicou é obrigatório")
        .transform((value, originalValue) => {
            if (originalValue) {
                return originalValue.replace(/[./-]/g, "");
            }
            return value;
        })
        .matches(
            /^([0-9]{14}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
            "Formato de CNPJ inválido"
        )
        .trim(),
    leadName: yup
        .string()
        .required("O nome da organização é obrigatório")
        .trim(),
    leadCnpj: yup
        .string()
        .required("O CNPJ do lead é obrigatório")
        .transform((value, originalValue) => {
            if (originalValue) {
                return originalValue.replace(/[./-]/g, "");
            }
            return value;
        })
        .matches(
            /^([0-9]{14}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
            "Formato de CNPJ inválido"
        )
        .trim(),
    formattedPhoneNumber1: yup
        .string()
        .required("O número principal do lead é obrigatório")
        .trim()
        .max(14)
        .min(10),
    formattedPhoneNumber2: yup.string().trim(),
    leadMailAddress: yup.string().email().trim(),
    street: yup
        .string()
        .required("A rua do endereço do lead é obrigatório")
        .trim(),
    number: yup
        .string()
        .required("O número do endereço do lead é obrigatório")
        .trim(),
    neighborhood: yup
        .string()
        .required("O bairro do endereço do lead é obrigatório")
        .trim(),
    zipcode: yup
        .string()
        .required("O CEP do endereço do lead é obrigatório")
        .length(8)
        .transform((value) =>
            value.length === 9 ? value.replace("-", "") : value
        )
        .trim(),
    city: yup
        .string()
        .required("A cidade do endereço do lead é obrigatório")
        .transform((value) =>
            value === "São Caetano do Sul"
                ? "São Caetano"
                : value === "São Bernardo do Campo"
                    ? "São Bernardo"
                    : value
        )
        .trim(),
    state: yup
        .string()
        .required("O estado do endereço do lead é obrigatório")
        .trim(),
    seller_id: yup.string().required().trim(),
    otherIndications: yup.boolean().default(false),
});

interface ISellerResponse {
    id: number;
    created_at: string;
    updated_at: string; 
    user_id: string;
    name: string;
    last_name: string;
    email: string;
    active: boolean;
  }
  

const ClientRecommendedForm = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [sendingRecommendation, setSendingRecommendation] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [recommendationTitle, setRecommendationTitle] = useState("");
    const [recommendationResult, setRecommendationResult] = useState("");
    const [cep, setCep] = useState("");
    const [cepInfos, setCepInfos] = useState<ICepInfo>();
    const [sellers, setSellers] = useState<ISellerResponse[]>([]);

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { data: cepInfosFromApi, isError: getCepInfosFromApiIsError } =
        useCepInfos(cep, {
            enabled: !!cep,
        });

    const { data: cepInfosFromApiV2, isError: getCepInfosFromApiIsErrorV2 } =
        useCepInfosV2(cep, {
            enabled: getCepInfosFromApiIsError,
        });

    const fetchSellers = async () => {
        try {
            const params = {per_page: 200, is_hunter: true, active: true};
            const response: AxiosResponse = await bitrixApiService.getBitrixSellers(params);
            setSellers(response?.data?.results);
        } catch {
            addPopup({
                title: "Erro ao buscar os hunters.",
                description: "Por favor, tente novamente mais tarde.",
                type: "error",
                duration: "permanent",
            });
        };
    }

    useEffect(() => {
        fetchSellers();
    }, []);
    
    const onSubmit = async (data: FieldValues) => {
        setSendingRecommendation(true);
        addPopup({
            title: "Recomendação enviada ao servidor.",
            description:
                "Por favor, espere até que uma nova mensagem seja exibida com o resultado da recomendação.",
            type: "info",
            duration: "permanent",
        });
    
        const mappedData = mapPayloadToClient(data);
        mappedData.seller_id = data.seller_id;
    
        setRecommendationTitle("Recomendação realizada.");
    
        try {
            const response = await bitrixApiService.postRecommendation(mappedData);            

            setRecommendationTitle("Recomendação enviada com sucesso!");
            setRecommendationResult(
                `A recomendação foi enviada com sucesso para o hunter ${response.data.hunterName}.`
            );
        } catch (error: any) {
            const serverMessage = error.response?.data?.error?.toLowerCase() || "";

            let errorMessage = "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.";

            if (serverMessage) {
                errorMessage = handleRecommendationError(serverMessage);
            }
    
            setRecommendationTitle("Erro ao enviar recomendação.");
            setRecommendationResult(errorMessage);
        } finally {
            setSendingRecommendation(false);
            setOpenAlertDialog(true);
        }
    };      

    const handleCepTextFildChange = (cep: string) => {
        if (validateCep(cep)) {
            if (cep.length === 9) {
                cep = cep.slice(0, 5) + cep.slice(6);
            }
            setCep(cep);
        }
    };

    useEffect(() => {
        if (cepInfosFromApi || cepInfosFromApiV2) {
            const cepInfos = cepInfosFromApi
                ? cepInfosFromApi
                : cepInfosFromApiV2;
            setCepInfos(cepInfos);
            setValue("state", cepInfos?.state);
            setValue("city", cepInfos?.city);
            setValue("neighborhood", cepInfos?.neighborhood);
            setValue("street", cepInfos?.street);
        }
    }, [cepInfosFromApi, cepInfosFromApiV2, setValue]);

    useEffect(() => {
        if (getCepInfosFromApiIsErrorV2) {
            addPopup({
                title: "Preencha o endereço manualmente.",
                description:
                    "Não foi possível buscar as informações de endereço, por favor preencha-as manualmente.",
                type: "info",
                duration: "permanent",
            });
        }


    }, [getCepInfosFromApiIsErrorV2])

    return (
        <div>
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="h6" marginTop={4} marginBottom={2}>Dados Gerais do Lead</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="CNPJ de quem indicou *"
                                type="text"
                                {...register("whoReferredCnpj")}
                                error={!!errors?.whoReferredCnpj}
                                helperText={<>{errors?.whoReferredCnpj?.message}</>}
                                inputProps={{ maxLength: 14 }}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Nome da Organização *"
                                type="text"
                                {...register("leadName")}
                                error={!!errors?.leadName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="CNPJ do Lead *"
                                type="text"
                                {...register("leadCnpj")}
                                error={!!errors?.leadCnpj}
                                helperText={<>{errors?.leadCnpj?.message}</>}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                                inputProps={{ maxLength: 14 }}

                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Dados de Contato do Lead</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Telefone 01 *"
                                type="text"
                                {...register("formattedPhoneNumber1")}
                                error={!!errors?.formattedPhoneNumber1}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                                fullWidth
                                inputProps={{ maxLength: 11 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Telefone 02"
                                type="text"
                                {...register("formattedPhoneNumber2")}
                                error={!!errors?.formattedPhoneNumber2}
                                onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                                fullWidth
                                inputProps={{ maxLength: 11 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Email"
                                type="text"
                                {...register("leadMailAddress")}
                                error={!!errors?.leadMailAddress}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Endereço do Lead: insira o CEP para buscar o endereço completo
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="CEP *"
                                placeholder="00000-000"
                                type="text"
                                {...register("zipcode")}
                                error={!!errors?.zipcode}
                                inputProps={{ maxLength: 9 }}
                                onChange={(event) => handleCepTextFildChange(event.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Logradouro *"
                                type="text"
                                {...register("street")}
                                error={!!errors?.street}
                                focused={!!cepInfos?.street}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Número *"
                                type="text"
                                {...register("number")}
                                error={!!errors?.number}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Bairro *"
                                type="text"
                                {...register("neighborhood")}
                                error={!!errors?.neighborhood}
                                focused={!!cepInfos?.neighborhood}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Estado *"
                                type="text"
                                {...register("state")}
                                error={!!errors?.state}
                                focused={!!cepInfos?.state}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...register("city")}
                                label="Cidade *"
                                variant="outlined"
                                error={!!errors?.city}
                                focused={!!cepInfos?.city}
                                helperText={<>{errors?.city?.message}</>}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Dados Gerais do Hunter</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={sellers}
                                getOptionLabel={(option) => option?.name + " " + option?.last_name}
                                className={classes.inputTextField}
                                onChange={(event, value) => {
                                    setValue("seller_id", value?.user_id);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...register("sellerName")}
                                        {...params}
                                        label="Nome de usuário"
                                        variant="outlined"
                                        error={!!errors?.sellerName}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box marginBottom={8}>
                                <Button
                                    disabled={sendingRecommendation}
                                    className={classes.buttonBox}
                                    type="submit"
                                    fullWidth
                                >
                                    <Typography variant="h4">Enviar</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {
                openAlertDialog && (
                    <AlertDialog
                        setOpenDialog={setOpenAlertDialog}
                        openDialog={openAlertDialog}
                        title={recommendationTitle}
                        message={recommendationResult}
                    />
                )
            }
        </div >
    );
};

export default ClientRecommendedForm;
