import { useCallback, useEffect, useRef, useState } from "react";
import { FieldValues, useForm, Controller } from "react-hook-form";
import {
    YYYYMMDDtoDDMMYYYY,
    todayMinusXAsString,
} from "../../../../../utils/dateFunctions";
import {
    Autocomplete,
    Box,
    Pagination,
    Stack,
    Typography,
} from "@mui/material";

import { useStyles } from "./styles";

import RestoreIcon from "@mui/icons-material/Restore";

import { ITeams } from "../../../../../interfaces/SellersApi/ITeams";
import { IHunterSales } from "../../../../../interfaces/AbacateApi/IHunterSales";
import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import {
    SdrBasedErrors,
    SdrBasedGenericResponses,
    SdrBasedResponses,
} from "../../../../../utils/Api/responses/responses";

import { usePopup } from "../../../../../hooks/usePopup";
import useHunterSales from "../../../../../hooks/AbacateApi/useHunterSales";
import useEditHunterSales from "../../../../../hooks/AbacateApi/useEditHunterSales";
import useTeams from "../../../../../hooks/SellersApi/useTeams";

import Button from "../../../../../components/Button";
import TextField from "../../../../../components/TextField";
import ComponentHeader from "../../../../../components/ComponentHeader";
import DataGrid from "../../../../../components/DataGrid";

import { Dayjs } from "dayjs";
import { validateCnpj } from "../../../../../utils/validateCnpj";

import { DatePicker } from "@mui/x-date-pickers";
import {
    DataGridProps,
    GridActionsCellItem,
    GridCellParams,
    GridColDef,
    GridRowId,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid";

import { AxiosError, AxiosResponse } from "axios";
import sellersApiService from "../../../../../services/SellersApi/sellersApiService";
import { HunterEditInputCell } from "./HunterEditInputCell";

interface IValidRowModelHunterSales extends GridValidRowModel, IHunterSales { }

const HunterSalesTable = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const apiRef = useGridApiRef();

    const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
    const unsavedChangesRef = useRef<{
        unsavedRows: Record<GridRowId, IValidRowModelHunterSales>;
        rowsBeforeChange: Record<GridRowId, IValidRowModelHunterSales>;
    }>({
        unsavedRows: {},
        rowsBeforeChange: {},
    });

    const [hunters, setHunters] = useState<IFrexcoSeller[]>([]);
    const [canGetHunterSales, setCanGetHunterSales] = useState(false);
    const { data: teams } = useTeams(
        {
            active: true,
            role__in: JSON.stringify(["Hunter"]),
        },
        {
            select: (resp: SdrBasedGenericResponses<ITeams> | undefined) =>
                resp?.results.map((team) => team.name),
        }
    );

    const validTeams = teams as unknown as string[] | undefined;

    const [queryParams, setQueryParams] = useState<FieldValues>({
        page_number: 1,
        errors: true,
        hunter_team: JSON.stringify(validTeams),
        start_date: todayMinusXAsString(30),
        end_date: todayMinusXAsString(0),
    });

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: {
            start_date: null as Dayjs | null,
            end_date: null as Dayjs | null,
            lead_cnpj: null,
            type: null,
            hunter: null,
            hunter_team: null,
        },
    });

    const [columnDefs, setColumnDefs] = useState<GridColDef<IHunterSales>[]>([
        {
            field: "actions",
            type: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<RestoreIcon />}
                        label="Desfazer"
                        disabled={
                            unsavedChangesRef.current.unsavedRows[id] ===
                            undefined
                        }
                        onClick={() => {
                            apiRef.current.updateRows([
                                unsavedChangesRef.current.rowsBeforeChange[id],
                            ]);
                            delete unsavedChangesRef.current.rowsBeforeChange[
                                id
                            ];
                            delete unsavedChangesRef.current.unsavedRows[id];
                            setHasUnsavedRows(
                                Object.keys(
                                    unsavedChangesRef.current.unsavedRows
                                ).length > 0
                            );
                        }}
                    />,
                ];
            },
        },
        { field: "lead_name", headerName: "Nome", flex: 1 },
        { field: "lead_cnpj", headerName: "CNPJ", flex: 1 },
        {
            field: "order_date",
            headerName: "Compra em",
            minWidth: 150,
            renderCell: (params: GridCellParams<IHunterSales, string>) => {
                return YYYYMMDDtoDDMMYYYY(params.value ? params.value : "");
            },
            flex: 1,
        },
        { field: "type", headerName: "Tipo", minWidth: 150, flex: 1 },
    ]);

    const { data: hunterSalesResponse, isFetching: fetchingHunterSales } =
        useHunterSales(queryParams, {
            enabled: !!canGetHunterSales,
        });

    const { mutateAsync: mutateHunterSales, isLoading: sendingHunterSales } =
        useEditHunterSales();

    const getSellers = useCallback(async () => {
        await sellersApiService
            .getFrexcoSellers({
                active: true,
            })
            .then(async (resp: AxiosResponse<SdrBasedResponses>) => {
                let count = resp.data.info.count;
                await sellersApiService
                    .getFrexcoSellers({
                        active: true,
                        per_page: count,
                        order_by: "name",
                    })
                    .then((resp: AxiosResponse<SdrBasedResponses>) => {
                        var sellers = resp.data.results;
                        sellers.push({
                            name: "inbound",
                            bitrix: {
                                external_id: "",
                            },
                            squads: ["inbound"],
                        });
                        setHunters(sellers);
                    });
            })
            .catch((err: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Falha ao buscar Hunters.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            });
    }, [addPopup]);

    const handleFilter = (filters: FieldValues) => {
        var nonEmptyFilters: FieldValues = {};
        let start_date: Dayjs | null = null;
        let end_date: Dayjs | null = null;
        if (!!filters.lead_cnpj) {
            if (!validateCnpj(filters.lead_cnpj)) {
                setError("lead_cnpj", {
                    type: "custom",
                    message: "Formato inválido.",
                });
                return;
            }
            filters.lead_cnpj = filters.lead_cnpj.replace(/[./-]/g, "");
        }

        Object.keys(filters).forEach((key) => {
            const value = filters[key];
            if (value && value !== "" && value !== undefined) {
                if (key === "start_date" || key === "end_date") {
                    nonEmptyFilters[key] = value.format("YYYY-MM-DD");
                    if (key === "start_date") {
                        start_date = value;
                    } else {
                        end_date = value;
                    }
                } else {
                    nonEmptyFilters[key] = `["${value}"]`;
                }
            }
        });

        if (start_date && end_date && start_date > end_date) {
            addPopup({
                title: "Erro ao filtrar.",
                description:
                    "A data de inicio não pode ser maior que a data final",
                type: "error",
                duration: "temporary",
            });
        } else {
            nonEmptyFilters = {
                ...nonEmptyFilters,
                page_number: 1,
            };
            setQueryParams(nonEmptyFilters);
        }
    };

    useEffect(() => {
        getSellers();
    }, [getSellers]);

    useEffect(() => {
        if (hunters?.length) {
          setColumnDefs((state) => [
            ...state,
            {
              field: "hunter",
              headerName: "Hunter",
              editable: true,
              renderCell: (params) => params.value,
              renderEditCell: (params) => (
                <HunterEditInputCell {...params} hunters={hunters} />
              ),
              flex: 1,
            },
          ]);
      
          setColumnDefs((state) => [
            ...state,
            { field: "hunter_team", headerName: "Equipe", flex: 1},
          ]);
        }
      }, [hunters]);

    useEffect(() => {
        if (validTeams) {
            setQueryParams((prevParams) => ({
                ...prevParams,
                hunter_team: JSON.stringify(validTeams),
            }));
            setCanGetHunterSales(true);
        }
    }, [validTeams]);

    const processRowUpdate: NonNullable<DataGridProps["processRowUpdate"]> = (
        newRow: IHunterSales,
        oldRow: IHunterSales
      ) => {
        try {
          const rowId = newRow?.id;
          if (!rowId) throw new Error("ID da linha não encontrado.");
      
          const newOwner = hunters?.find((hunter) => hunter.name === newRow.hunter);
          if (!newOwner) throw new Error("Hunter não encontrado.");
      
          newRow.hunter_team = newOwner.squads?.[0] || "without_team";
          newRow.bitrix_user_id = newOwner.bitrix?.external_id || "";
      
          unsavedChangesRef.current.unsavedRows[rowId] = newRow;
      
          if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
          }
      
          setHasUnsavedRows(true);
          return newRow;
        } catch (error) {
          console.error("Erro no processRowUpdate:", error);
          throw error;
        }
      };

    const saveChanges = () => {
        mutateHunterSales(
            Object.values(unsavedChangesRef.current.unsavedRows)
        ).then(() => {
            setHasUnsavedRows(false);
            unsavedChangesRef.current = {
                unsavedRows: {},
                rowsBeforeChange: {},
            };
        });
    };

    return (
        <>
            <Box className={classes.container}>
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Registro de vendas" />
                    <ComponentHeader.Desc
                        text="Por essa tabela, ao dar dois cliques na célula, você
                        consegue alterar a data da compra ou o vendedor
                        responsável pela venda. Os registros que são mostrados
                        ao entrar na página são registros que contém algum tipo
                        de erro relacionado ao vendedor responsável."
                    />
                </ComponentHeader.Root>
                <form
                    onSubmit={handleSubmit(handleFilter)}
                    className={classes.filtersBox}
                >
                    <Box className={classes.datesFiltersBox}>
                        <Controller
                            control={control}
                            rules={{ required: false }}
                            name="start_date"
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        label="Data Inicial"
                                        value={field.value}
                                        disableFuture
                                        format="DD/MM/YYYY"
                                        inputRef={field.ref}
                                        onChange={(start_date) => {
                                            field.onChange(start_date);
                                        }}
                                    />
                                );
                            }}
                        />

                        <Controller
                            control={control}
                            rules={{ required: false }}
                            name="end_date"
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        label="Data Final"
                                        value={field.value}
                                        disableFuture
                                        format="DD/MM/YYYY"
                                        inputRef={field.ref}
                                        onChange={(end_date) => {
                                            field.onChange(end_date);
                                        }}
                                    />
                                );
                            }}
                        />
                        <TextField
                            variant="outlined"
                            placeholder="CNPJ"
                            label="CNPJ"
                            type="text"
                            {...register("lead_cnpj")}
                            error={!!errors.lead_cnpj}
                            helperText={<>{errors.lead_cnpj?.message}</>}
                        />
                        <Autocomplete
                            id="types"
                            className={classes.multiSelect}
                            options={[
                                { id: 1, label: "activation" },
                                { id: 2, label: "gain" },
                            ]}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Tipo."
                                    placeholder="Tipo"
                                    {...register("type")}
                                />
                            )}
                        />
                        <Autocomplete
                            id="hunters"
                            className={classes.multiSelect}
                            options={hunters}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Hunter."
                                    placeholder="Hunter"
                                    {...register("hunter")}
                                />
                            )}
                        />
                        <Autocomplete
                            id="squads"
                            className={classes.multiSelect}
                            options={validTeams ? validTeams : []}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Polo."
                                    placeholder="Polo"
                                    {...register("hunter_team")}
                                />
                            )}
                        />
                    </Box>
                    <Box className={classes.buttonsBox}>
                        <Button type="submit" loading={fetchingHunterSales}>
                            <Typography variant="body2">Filtrar</Typography>
                        </Button>
                        <Button
                            type="button"
                            loading={fetchingHunterSales}
                            onClick={() => {
                                setQueryParams({
                                    page_number: 1,
                                    errors: true,
                                    hunter_team: JSON.stringify(validTeams),
                                    start_date: todayMinusXAsString(30),
                                    end_date: todayMinusXAsString(0),
                                });
                            }}
                            sx={{
                                backgroundColor: "#ED6C02",
                                "&:hover": {
                                    backgroundColor: "#C0570C",
                                },
                            }}
                        >
                            <Typography variant="body2">
                                Registros com erro
                            </Typography>
                        </Button>
                        <Button
                            type="button"
                            onClick={saveChanges}
                            loading={sendingHunterSales}
                            disabled={!hasUnsavedRows}
                        >
                            <Typography variant="body2">Salvar</Typography>
                        </Button>
                    </Box>
                </form>

                <Box width={"100%"} height={"500px"} marginTop={"15px"}>
                    <DataGrid<IHunterSales>
                        apiRef={apiRef}
                        processRowUpdate={processRowUpdate}                        
                        rows={
                            hunterSalesResponse?.results
                                ? hunterSalesResponse?.results
                                : []
                        }
                        columns={columnDefs}
                        hideFooterPagination
                        loading={fetchingHunterSales || sendingHunterSales}
                        getRowClassName={({ id }) => {
                            const unsavedRow =
                                unsavedChangesRef.current.unsavedRows[id];
                            if (unsavedRow) {
                                return "row--edited";
                            }
                            return "";
                        }}
                    />
                    {hunterSalesResponse && (
                        <Box className={classes.paginationBox}>
                            <Stack spacing={2}>
                                <Pagination
                                    count={Math.ceil(
                                        hunterSalesResponse?.total_count /
                                        hunterSalesResponse?.page_size
                                    )}
                                    page={queryParams.page_number}
                                    onChange={(e, value) =>
                                        setQueryParams((prevState) => ({
                                            ...prevState,
                                            page_number: value,
                                        }))
                                    }
                                    shape="rounded"
                                />
                            </Stack>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default HunterSalesTable;
