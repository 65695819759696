import { api } from "../utils/Api/apiClient";

import { FieldValues } from "react-hook-form";

interface IBitrixSeller {
    per_page: number;
    is_hunter: boolean;
    active: boolean;
}   

const bitrixApiService = {
    postRecommendation: async (recommendation: FieldValues) => {
        const url = `api/bitrix/v1/recommendation`;
        return await api.patch(url, recommendation);
    },
    getBitrixSellers: async (params: IBitrixSeller) => {
        const url = `api/bitrix/v1/seller`;
        return await api.get(url, { params });
        },
        getDistributionsSummary: async (params: any): Promise<any> => {
        const url = `api/bitrix/v1/distribution`;
        return await api.get(url, { params });
    },
    
};

export default bitrixApiService;
